<template>
  <main class="table">
    <app-table
      :headers="tableHeader"
      :data="data"
      :loading="isLoading"
    >
      <template slot="loading">
        <app-spinner />
      </template>

      <template slot="empty-table">
        <div class="empty-logs">
          <noData />
          <p>No templates yet? Go ahead, give it a try! 👀</p>
        </div>
      </template>

      <template slot="templateId" slot-scope="{ data }">
        <small>{{ data.templateId }}</small>
      </template>

      <template slot="editButton" slot-scope="{ data }">
        <app-button look="warning" title="Duplicate" @click="duplicate(data)">
          Duplicate
        </app-button>
        <app-button title="Navigate to template update form">
          <router-link :to="{ name: 'update-template', params: { templateId: data.templateId } }">Show</router-link>
        </app-button>
      </template>
    </app-table>
  </main>
</template>

<script>
import noData from '@/assets/img/no-data.svg?inline';
import templatesApi from '@/services/api/templates';

export default {
  name: 'templates-table',

  components: {
    noData,
  },

  props: {
    data: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableHeader: [
        { label: 'Type', key: 'renderType', size: 'small-2' },
        { label: 'Name', key: 'name', size: 'small-5' },
        { label: 'Template Id', key: 'templateId', size: 'small-3' },
        { label: ' ', key: 'editButton', size: 'small-2' },
      ],
    };
  },

  methods: {
    async duplicate(template) {
      try {
        await templatesApi.createTemplate({
          name: `${template.name} Copy`,
          content: template.content,
          renderType: template.renderType,
          defaultData: template.defaultData,
          parameters: template.parameters,
        });
        this.$emit('refresh');

        this.$notification.show({
          title: 'Success',
          text: 'Template has been duplicated!',
        });
      } catch (error) {
        if (error.response.data.message.startsWith('Template limit reached')) {
          this.$message.show({
            title: 'Max template limit reached :(',
            text: 'You’ve reached your plan’s maximum template limit. Feel free to upgrade for access to more templates - we’d love to see what you create!',
            confirmText: 'Ok',
            hasCancel: false,
          });
        } else {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to duplicate your template. Please try again later.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }
    },
  },
};
</script>

<style lang="sass">
.table
  width: 100%
  .app-table .header
    column-gap: 0

  .empty-logs
    display: flex
    flex-direction: column
    align-items: center
    margin-left: -2.5rem
    svg
      margin-top: 10px
      max-width: 100px
      transform: translateX(15px)
    p
      padding: 2rem 0 0

  .none
    width: 20px

    span
      display: block
      margin: auto
      width: 10px
      height: 1px
      background: $primary40

  .app-button ~ .app-button
    margin-left: 1rem

  small
    color: white

</style>
