<template>
  <section class="page templates">
    <p class="annotation">
      Introducing our new template functionality to improve your HTML conversions. Create a template and save it. <br>
      When you need to get the PDF, simply call the API using the template ID, add your variables, and define the API parameters - it’s that easy! <br>
      You have a problem or a question? Support is here for you!
    </p>

    <p class="info">
      Create a template, use its ID to call the Doppio API, add your data, and easily generate your PDFs or images.
      If you're looking for more information on how to use templates, you can find it on our
        <a :href="`${docBaseUrl}/guide/cookbook/using-templates.html`" target="_blank">templates documentation</a>
    </p>

    <templates-table :data="templates" :loading="isLoading" @refresh="getTemplates()"/>

    <!-- <app-button class="create" title="New template">
      <router-link :to="{ name: 'create-template' }">New template</router-link>
    </app-button> -->

    <app-button class="create" title="New template" @click="isModalVisible = true">
      New template
    </app-button>

    <app-modal :show.sync="isModalVisible">
      <h1>Create a new template</h1>
      <p>You can create a template using one of our pre-designed models or start from a blank page.</p>
      <app-button look="tertiary" class="close" @click="isModalVisible = false">Cancel</app-button>
      <div class="card-container">
        <div class="template-card" @click="createTemplate('default')">
          <img src="@/assets/img/templates.svg" alt="file icon" />
          PDF from blank
        </div>
        <div class="template-card" @click="createTemplate('screenshot')">
          <img src="@/assets/img/templates.svg" alt="file icon" />
          Screenshot from blank
        </div>
        <div class="template-card" @click="createTemplate('invoice')">
          <img src="@/assets/img/billing.svg" alt="billing icon" />
          Invoice <br/> (PDF)
        </div>
        <div class="template-card" @click="createTemplate('contract')">
          <img src="@/assets/img/logs.svg" alt="log icon" />
          Contract <br/>(PDF)
        </div>
        <div class="template-card" @click="createTemplate('banner')">
          <img src="@/assets/img/overview.svg" alt="stats icon" />
          Social banner <br/>(Screenshot)
        </div>
      </div>
    </app-modal>
  </section>
</template>

<script>
import templatesApi from '@/services/api/templates';
import templateModels from '@/services/data/templates';
import base64 from '@/services/utils/base64';
import TemplatesTable from './TemplatesTable.vue';

export default {
  name: 'templates',
  components: {
    TemplatesTable,
  },
  data() {
    return {
      me: null,

      templates: null,

      isLoading: false,

      docBaseUrl: process.env.VUE_APP_DOC_URL,

      isModalVisible: false,

      templateModels,
    };
  },
  async mounted() {
    await this.getTemplates();
  },
  methods: {
    async getTemplates() {
      this.isLoading = true;

      try {
        this.templates = await templatesApi.getTemplates();
      } catch (error) {
        this.$message.show({
          title: 'Error',
          text: 'Impossible to get your templates',
          confirmText: 'Ok',
          hasCancel: false,
        });

        this.templates = [];
      } finally {
        this.isLoading = false;
      }
    },
    async createTemplate(type) {
      let templateModel = this.templateModels.default;
      if (this.templateModels[type]) {
        templateModel = this.templateModels[type];
      }

      // Init template
      const newTemplate = {};
      newTemplate.name = `${templateModel.name} - ${this.displayDateTime()}`;
      newTemplate.content = base64.convertTextToBase64(templateModel.content);
      newTemplate.renderType = templateModel.renderType;
      newTemplate.parameters = templateModel.parameters;
      newTemplate.defaultData = templateModel.defaultData;

      // Create
      try {
        this.isModalVisible = false;
        const createdTemplate = await templatesApi.createTemplate(newTemplate);
        this.$router.push({ name: 'update-template', params: { templateId: createdTemplate.templateId } });
      } catch (error) {
        if (error.response.data.message.startsWith('Template limit reached')) {
          this.$message.show({
            title: 'Max template limit reached :(',
            text: 'You’ve reached your plan’s maximum template limit. Feel free to upgrade for access to more templates - we’d love to see what you create!',
            confirmText: 'Ok',
            hasCancel: false,
          });
        } else {
          this.$message.show({
            title: 'Error',
            text: 'Impossible to create your template. Please try again later.',
            confirmText: 'Ok',
            hasCancel: false,
          });
        }
      }
    },
    displayDateTime() {
      const now = new Date();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const year = now.getFullYear();
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');

      return `${month}/${day}/${year} ${hours}:${minutes}`;
    },
  },
};
</script>

<style lang="sass">
.templates
  padding: 2rem 4rem
  .info
    margin-bottom: 0.5rem
    font-size: 1rem
    a, strong
      color: white
      opacity: 0.9
  .annotation
    margin-bottom: 2rem
    padding: 1rem 1.5rem
    font-size: 1rem
    line-height: 1.7
    border: 1px solid $primary
    border-radius: $global-border-radius
    background: linear-gradient(90deg, transparent, #e345ff26) 0 0
  .create
    margin: 2rem 0 0 0

  .card-container
    display: flex
    flex-wrap: wrap
    justify-content: center
  .template-card
    display: inline-flex
    flex-direction: column
    align-items: center
    margin: 1rem
    padding: 3rem 2.5rem 0
    width: 12rem
    height: 12rem
    background: $primary80
    border: 1px solid $primary
    border-radius: $global-border-radius
    color: $white
    line-height: 1.5
    text-align: center
    text-wrap: balance
    transition: all ease-in 0.3s
    cursor: pointer
    &:hover
      filter: brightness(1.3)
    img
      margin-bottom: 1rem
      max-width: 30px

  .modal-wrapper
    padding: 2rem
    min-width: 60vw
    max-height: 100vh
    overflow-y: scroll
    background: $primary95
    .modal-content
      position: relative
    .close
      position: absolute
      top: -2rem
      right: 1rem
    h1
      color: white
    p
      margin: 2rem 0
    .app-button
      margin-top: 3rem

</style>
