const utils = {};

const convertTextToBase64 = (text) => btoa(String.fromCodePoint(...new TextEncoder().encode(text)));

const convertBase64ToText = (base64) => {
  const binString = atob(base64);
  return new TextDecoder().decode(Uint8Array.from(binString, (m) => m.codePointAt(0)));
};

utils.convertTextToBase64 = convertTextToBase64;
utils.convertBase64ToText = convertBase64ToText;

export default utils;
