import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

const getTemplates = async () => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/templates`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const getOneTemplate = async (templateId) => {
  try {
    const response = await axios.get(`${API_URL}/dashboard/templates/${templateId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const createTemplate = async ({
  name,
  content,
  parameters,
  renderType,
  defaultData,
}) => {
  try {
    const response = await axios.post(`${API_URL}/dashboard/templates`, {
      name,
      content,
      parameters,
      renderType,
      defaultData,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const updateTemplate = async (templateId, {
  name,
  content,
  parameters,
  renderType,
  defaultData,
}) => {
  try {
    const response = await axios.put(`${API_URL}/dashboard/templates/${templateId}`, {
      name,
      content,
      parameters,
      renderType,
      defaultData,
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const deleteTemplate = async (templateId) => {
  try {
    const response = await axios.delete(`${API_URL}/dashboard/templates/${templateId}`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

const api = {};
api.getTemplates = getTemplates;
api.getOneTemplate = getOneTemplate;
api.createTemplate = createTemplate;
api.updateTemplate = updateTemplate;
api.deleteTemplate = deleteTemplate;

export default api;
